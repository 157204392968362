var arAdagioListDebug=[];
var currentAdvId;
var currentAdvType;
var lastAdagioCall="";


var oBannerInfo = {
    'SiteArea':'',
    'BannerId':'fmt_edidomus',
    'Formats':'',
    'Keyword':'',
    'TypeList':[],
    'DivList':[],
    'AddToList':true,
    Add:function(bType,bId){
        this.DivList[this.DivList.length++]=bId;
        if(!bType)bType="";
        this.TypeList[this.TypeList.length++]=bType;
    },
    getFormats:function(){
        return this.getArFormats("string");
    },
    getArFormats:function(what){
        var ar=this.Formats.split(",");
        for(var i=0;i<ar.length;i++){
            ar[i]=ar[i].trim();
        }
        this.Formats = ar.join(",");
        if(what&&what=="string")
            return this.Formats;
        else
            return ar;
    }    
}

function showAdagioContent(i){
    alert(arAdagioListDebug[i].content);
}

function showAdagioReportDebug(){
    var initTable = "<table width='580px' style='font-size:12px' border='1'>";
    var report="";
    var out="";
    var list = oBannerInfo.Formats.split(",");
    var pos=0;
    var lastpos=0;
   
    
    report+=initTable ;
    report+="<tr><td>SiteArea</td><td align='left'>"+oBannerInfo.SiteArea+"</td></tr>";
    report+="<tr><td>Formati ADV</td><td align='left'>"+list .join("<br>")+"</td></tr>";
    report+="<tr><td>Keyword</td><td align='left'>"+oBannerInfo.Keyword.split("/").join("<br>")+"</td></tr>";
    report+="</table><br>";
    
    report+=initTable 
    for(var i=0;i<arAdagioListDebug.length;i++){
        report+="<tr>";
        report+="<td>" + arAdagioListDebug[i].type + "</td>";
        report+="<td>" + arAdagioListDebug[i].id +"</td>";
        report+="<td>" + (arAdagioListDebug[i].exists?"<a href='javascript:showAdagioContent(" + i + ")'>Si</a>":"No") +"</td>";
        report+="</tr>";
    }
    report+="</table>";
    $('#alertBox').width(600);
    alert(report);
}

function debugAdv(advContent){
    var exists=(advContent&&advContent!=''?true:false);
    var ix = arAdagioListDebug.length
    if(exists)lastAdagioCall=currentAdvId;
    arAdagioListDebug[ix]={'type':currentAdvType,'id':currentAdvId,'exists':exists,'content':advContent};
}

function refreshBanner(){
    try{
    adagioWriteTagRefresh(oBannerInfo.SiteArea,oBannerInfo.BannerId,'',oBannerInfo.getFormats(),0,'','','',oBannerInfo.Keyword);
    }catch(ex){}
}

function qrtWriteBanner(bTypeList,bId,viewDebug){
    var TypeList;
    var bHTML ="";
    var bType = "";
    var isArrayParam = (bTypeList instanceof Array);
    if(!isArrayParam)TypeList = bTypeList.split(",");
    else TypeList=bTypeList;
    if(viewDebug&&viewDebug==true)alert("qrtWriteBanner:"+ bTypeList + "/" +bId);
    currentAdvType = bTypeList;
    currentAdvId = bId;
    if(!adagioNOPrint){
        
        var saveDocumentWrite = document.write;
        document.write = function(txt){
            if(adagioAdvDebug)debugAdv(txt);
            $("#"+bId).html(txt);
        };
        bType = new String(TypeList[0]);
        if(oBannerInfo.AddToList)
            oBannerInfo.Add(bType,bId);
        try{adagioWriteBanner(bType);  }catch(ex){}
        document.write = saveDocumentWrite;
        if(adagioAdvDebug)if(lastAdagioCall!=currentAdvId)debugAdv("");
    }
    else if(adagioNOPrint==true){ 
        var oDiv = $("#"+bId);
        for(var i=0;i<TypeList.length;i++){
            bType = new String(TypeList[i]);
            oBannerInfo.Add(bType,bId);
            try{bHTML = adagioWriteBanner(bType);}catch(ex){}
            oDiv.html(bHTML);
        }
    }
}

var typeList = oBannerInfo.TypeList;
var divList = oBannerInfo.DivList; 